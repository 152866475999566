<template>
  <b-card>
    <div slot="header">
      <strong>Remover Regra</strong>
    </div>
    <p><router-link :to="{ name: 'Listar Regras' }">Listar Regras</router-link></p>

    <notification v-bind:notifications="notifications"></notification>

    <form v-on:submit.prevent="deleteRegra">
      <div class="alert alert-danger">
        <strong>Atenção</strong>
        <p>
          Deseja realmente remover este regra?<br>
          Esta ação não é reversível.
        </p>
        <p><button class="btn btn-danger">Remover regra</button></p>
      </div>
    </form>

  </b-card>
</template>

<script>
import Notification from './Notificar.vue'
export default {
  data () {
    return {
      notifications: []
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.getRegra()
        return true
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    getRegra: function () {
      this.notifications.push({
        type: 'danger',
        message: 'Esta regra não pode ser removida...'
      })
    },
    deleteRegra: function () {
      this.$router.push({name: 'Listar Regras'})
    }
  },
  components: {
    'notification': Notification
  }
}
</script>
